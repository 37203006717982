// import $ from 'jquery';
import moment from "moment";
import "lodash"
import "clndr";
import {isEmpty} from 'lodash';

$(function () {
    moment.locale("ja", {
        months: '01_02_03_04_05_06_07_08_09_10_11_12'.split('_'),
        monthsShort: '01_02_03_04_05_06_07_08_09_10_11_12'.split('_'),
    })

    // Calendar Template
    const clndrTemplate =
        `<div class='clndr-controls'> 
        <div class='clndr-control-button'> 
            <span class='clndr-previous-button'> 
                <span class='material-icons'>keyboard_arrow_left</span> 
            </span>
        </div> 
        <div class='month clndr-select-month-button'>
            <span class='year-num'><%= year %></span> 
            <span class='month-num'><%= month %></span> 
        </div> 
        <div class='clndr-control-button rightalign'> 
            <span class='clndr-next-button'> 
                <span class='material-icons'>keyboard_arrow_right</span> 
            </span> 
        </div>
    </div> 
    <table class='clndr-table' border='0' cellspacing='0' cellpadding='0'> 
        <thead> 
            <tr class='header-days'> 
                <% for(var i = 0; i < daysOfTheWeek.length; i++) 
                    { %><td class='header-day <%= daysOfTheWeek[i].toLowerCase() %>'><%= daysOfTheWeek[i] %></td ><% } 
                %> 
            </tr> 
        </thead> 
        <tbody>  
            <% for(var i = 0; i < numberOfRows; i++){ %> 
                <tr> 
                    <% for(var j = 0; j < 7; j++){ %> 
                        <% var d = j + i * 7; %>  
                        <td class='<%= days[d].classes %>'> 
                            <div class='day-contents'> <%= days[d].day %></div>
                        </td>
                    <%}%>
                </tr>
            <% } %> 
        </tbody> 
    </table> `;

    // Get List Event Template
    const getTemplateday = (date, el, areas) => {
        const year = date[0];
        const month = date[1];
        const day = date[2];
        const eventListTemplate =
            `<h3>
                <time datetime="${year}-${month}-${day}">
                    <span class="ym-num">${year}.${month}</span>
                    <span class="day-num">${day}</span>
                </time>
            </h3>
            ${isEmpty(areas) ?
                `<div>
                    <ul>
                        <li>本日のイベントはありません。</li>
                    </ul>
                </div>` :
                `<div class="event-list-inner">
                    <ul>
                        ${ 
                            areas.map(element => 
                                `<li>
                                    <dl>
                                        <dt>
                                            <span class='r-icon ${ element[1].slug }'>${ element[1].label }</span>
                                        </dt>
                                        ${ element[1].events.map(item => 
                                            `<dd>
                                                <a href='${ item.url }'>${ item.title }
                                                    <span class="entry-date">${item.start}～${item.end}</span>
                                                </a>
                                            </dd>`
                                        ).join('')}
                                    </dl>
                                </li>`
                            ).join('')
                        }    
                    </ul>
                </div>`
                }`
        el.html(eventListTemplate);
    }

    // Get Data By Day
    const getDataByDay = (data) => {
        const day = data.format("YYYYMMDD")
        $.ajax({
            url: jomon_event_config.api,
            type: 'post',
            data: {
                nonce: jomon_event_config.nonce,
                action: jomon_event_config.day_action,
                query: day
            },
            success: function (response) {
                if (response.status) {
                    const eventListEl = $(".event-list");
                    const date = data.format("YYYY-MM-DD").split("-");
                    const areas = Object.entries(response.areas);
                    getTemplateday(date, eventListEl, areas);
                }
            }
        });
    }

    // Get Data By Month
    const getDataByMonth = (data) => {
        const dataByMonth = data.format("YYYYMM");
        $.ajax({
            url: jomon_event_config.api,
            type: 'post',
            data: {
                nonce: jomon_event_config.nonce,
                action: jomon_event_config.month_action,
                query: dataByMonth
            },
            success: function (response) {
                if (response.status) {
                    let eventsMonth = response.events;
                    let eventDay;
                    for (var key in eventsMonth) {
                        if (eventsMonth[key]) {
                            eventDay = data.format("YYYY-MM-") + key;
                            calendar.addEvents([
                                {
                                    date: moment(eventDay, "YYYY-MM-DD")
                                }
                            ]);
                        }
                    }
                }
            }
        });
    }

    let calendar = $('.template-calendar').clndr({
        template: clndrTemplate,
        ready: function () {
            $.when(
                getDataByDay(moment()),
                getDataByMonth(moment()),
            ).then(function () {
                console.log("completed")
            })
        },
        clickEvents: {
            click: function (target) {
                getDataByDay(target.date)
            },
            onMonthChange: function (month) {
                getDataByMonth(month)
            },
        },
        weekOffset: 1,
        formatWeekdayHeader: function (day) {
            return day.format('ddd');
        }
    });
});

